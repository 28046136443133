
import React from 'react';
import './Contact.css'
import Navbar from '../../components/Navbar/Navbar';
import RookTech from '../../images/rook-no-bg.png';
import { FaLinkedin } from 'react-icons/fa';
import Footer from '../../components/Footer/Footer';


const ContactPage = () => {
  return (
    <div>
      <Navbar />
        <section className="contact">
          <div className="contactBox">
            <h2>Contact Us</h2>
            <p>You can email us at contact@rook-tech.com and we will be happy to reply ASAP!</p>
            <a className="contactIconLinkedin" href="https://www.linkedin.com/company/rook-tech-llc/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={30}/></a>
          </div>
        <img className='backgroundImage' src={RookTech} alt="Logo Home" />
      </section>
      <Footer />
    </div>
  );
};

export default ContactPage;
