import React from 'react';
import '../Footer/Footer.css';

function Footer() {
  return (
    <footer>
    <div className='copyright'>Made with ♜️ © 2024 Rook Tech LLC</div>
    </footer>
  );
}

export default Footer;