import React from 'react';
import './About.css';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import RookTech from '../../images/rook-no-bg.png';

const About = () => {
  return (
    <main>
      <Navbar />
      <section className="about">
        <br />

        <div className='aboutTextBlock'>
        
        <h2>About Us</h2>
        
        <p className='aboutTypography'>Welcome to Rook Tech!</p>

        <p>Where innovation meets purpose.</p>

        <p>We are an application development company dedicated to creating impactful and accessible products tailored to the unique needs of the developing world. </p>
 
        <p>Our mission is to leverage cutting-edge technology to solve real-world problems, enhance connectivity, and improve the quality of life for communities around the globe.</p>

        <p>Join us on our journey to make a meaningful difference through thoughtful and inclusive digital solutions.</p>
        </div>
        <img className="backgroundImage" src={RookTech} alt="Rook Tech Logo" />
      </section>
      <Footer />
    </main>
  );
};

export default About;
