import React from 'react';
import '../Services/Services.css'
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import RookTech from '../../images/rook-no-bg.png';

const Services = () => {
    return (
    <main>  
    <Navbar />
    <section className='services'>

    <div className='containerServices'>
        
        <h2 className='title'>Stay tuned!</h2>
        
        <p className='subtitle'>The good stuff is yet to come!</p>
        
        </div>

    <img className='backgroundImage' src={RookTech} alt="Logo mission page" />
    </section>            
    <Footer />
    </main>
    );
};


export default Services;