import React from 'react';
import './Home.css'
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import RookTech from '../../images/rook-no-bg.png';
import RookTechIcon from '../../images/RookTechIcon.png';

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <section className="home">

        <div className='homeContainer'>
          <p className='homeSubtitles'>Welcome to</p>
        
          <div className='homeTextContainer'>
            <img className='homeIcon' src={RookTechIcon} alt="RookTech Icon" />
            <span className='homeTitle'>Rook Tech</span>
          </div>
        
          <p className='homeSubtitles'>We create new technologies!</p>
        </div>
        
        <img className='backgroundImage' src={RookTech} alt="Logo Home" />
      </section>
      <Footer />
    </div>

  );
};


export default HomePage;
