import React from 'react';
import '../Mission/Mission.css'
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import RookTech from '../../images/rook-no-bg.png';

const Mission = () => {
    return (
        <main>
        <Navbar />
        <section className='mission'>
        <br />

        <div className='ourVision'>
        
        <h2 className='missionTitles'>Our Vision</h2>
        
        <p>At Rook Tech, our vision is to become a global leader in application development by harnessing the power of technology to create innovative and sustainable solutions for the developing world. </p>
        <br/>
        <p>We envision a future where every community, regardless of geographical and economic barriers, has access to digital tools that enhance quality of life, foster growth, and drive positive change.</p>

        </div>

        <div className='ourMission'>
        
        <h2 className='titles'>Our Mission</h2>
        
        <p>Our mission at Rook Tech is to design and develop high-quality, user-centric applications that address the unique challenges faced by communities in the developing world.</p>
        <br/>
        <p>We are committed to empowering individuals and organizations through technology, promoting digital inclusion, and driving social impact. By focusing on collaboration, innovation, and integrity, we aim to build solutions that not only meet immediate needs but also pave the way for long-term development and prosperity.</p>

        </div>

        <img className='backgroundImage' src={RookTech} alt="Logo mission page" />

        </section>
        <Footer />
        </main>
    );
};


export default Mission;